import { ApolloLink } from '@apollo/client';

export const cloudflareAccessLink = new ApolloLink((operation, forward) => {
  if (
    process.env.APP_ENV !== 'production' &&
    process.env.CLOUDFLARE_ACCESS_CLIENT_ID &&
    process.env.CLOUDFLARE_ACCESS_CLIENT_SECRET
  ) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'CF-Access-Client-Id': process.env.CLOUDFLARE_ACCESS_CLIENT_ID,
        'CF-Access-Client-Secret': process.env.CLOUDFLARE_ACCESS_CLIENT_SECRET,
      },
    }));
  }

  return forward(operation);
});

export default cloudflareAccessLink;
